"use client";

import React, { useState } from "react";
import { useIntersection, useLocalStorage } from "react-use";

const SIDEBAR_OPEN_KEY = "filterSidebarOpen";

const MarketplaceSidebarContext = React.createContext<{
  isOpen: boolean;
  isMobileOpen: boolean;
  intersectionRef: React.MutableRefObject<HTMLButtonElement | null> | null;
  intersection: IntersectionObserverEntry | null;
  toggleIsOpen(): void;
  toggleIsMobileOpen(): void;
}>({
  isOpen: false,
  isMobileOpen: false,
  intersectionRef: null,
  intersection: null,
  toggleIsOpen() {},
  toggleIsMobileOpen() {},
});

export function useMarketplaceSidebarState() {
  return React.useContext(MarketplaceSidebarContext);
}

interface MarketplaceSidebarProviderProps {
  children: React.ReactNode;
}
export default function MarketplaceSidebarProvider({
  children,
}: MarketplaceSidebarProviderProps) {
  const intersectionRef = React.useRef(null);
  const intersection = useIntersection(intersectionRef, {
    root: null,
    rootMargin: "0px",
    threshold: 1,
  });
  // Desktop sidebar state
  const [isOpen, setIsOpen] = useLocalStorage(
    SIDEBAR_OPEN_KEY,
    true
  );

  // Mobile sidebar state
  const [isMobileOpen, setIsMobileOpen] = useState<boolean>(false);

  const state = {
    isOpen: !!isOpen,
    isMobileOpen,
    intersection,
    intersectionRef,
    toggleIsOpen: () => setIsOpen(!isOpen),
    toggleIsMobileOpen: () => setIsMobileOpen(!isMobileOpen),
  };

  return (
    <MarketplaceSidebarContext.Provider value={state}>
      {children}
    </MarketplaceSidebarContext.Provider>
  );
}
