'use client'
import React from 'react'
import {
  Box,
  Skeleton,
  Typography,
  Divider,
  styled,
  TypographyProps,
} from '@mui/material'

import { Button } from '@hubble/web'
import {
  IconBrandInstagram,
  IconBrandFacebook,
  IconBrandDiscord,
  IconBrandTwitch,
  IconBrandYoutube,
  IconBrandReddit,
  IconGlobe,
  IconBrandX,
  IconBrandTwitter
} from '@hubble/icons/web'
import { web } from '@hubble/tokens'
import { IconEditFilled, IconMoreHorizontal } from '@hubble/icons'
import {
  Avatar,
  HubbleGrid,
  HubbleGridChild,
  HubbleMenu,
  HubbleSheet,
  Tooltip,
} from '@/elements/src'

import { useRouter } from 'next/navigation'
import { useUser } from '@/providers/UserContext'
import useMediaQueryState from '@/hooks/useMediaQueryState'
import { useProfileContext } from '@/providers/ProfileContext'

import { URL } from '../../../../constants'

export const SOCIAL_ICON_MAP = {
  Twitter: IconBrandTwitter,
  Instagram: IconBrandInstagram,
  Reddit: IconBrandReddit,
  X: IconBrandX,
  Twitch: IconBrandTwitch,
  Facebook: IconBrandFacebook,
  YouTube: IconBrandYoutube,
  Discord: IconBrandDiscord,
  Website: IconGlobe,
}

const StyledProfileContainerMobile = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 2rem;
`
const StyledProfileContainerDesktop = styled(StyledProfileContainerMobile)`
  flex-direction: row;
  gap: 32px;
  margin-top: 4rem;
`

const StyledProfileContainerPicMobile = styled(Box)`
  gap: 12px;
  display: flex;
  align-items: center;
  flex-direction: row;
`

const StyledProfilePicMobile = styled(Avatar)`
  max-width: 84px;
  max-height: 84px;
  width: 84px;
  height: 84px;
  flex-grow: 1;
  margin: 0;
`

const StyledProfilePicDesktop = styled(StyledProfilePicMobile)`
  max-width: 164px;
  max-height: 164px;
  width: 164px;
  height: 164px;
`

const StyledProfilePicSkeleton = styled(Skeleton)`
  max-width: 84px;
  max-height: 84px;
  width: 84px;
  height: 84px;
  flex-grow: 1;
  margin: 0;
`
const StyledProfilePicSkeletonDesktop = styled(Skeleton)`
  max-width: 164px;
  max-height: 164px;
  width: 164px;
  height: 164px;
  flex-grow: 1;
  margin: 0;
`

const StyledNameMobile = styled(Typography)<{
  component: TypographyProps['component']
}>`
  font-size: 28px;
  font-weight: 700;
  line-height: 36px;
  color: ${web.color.base.black};
`

const StyledNameDesktop = styled(StyledNameMobile)`
  font-size: 36px;
  line-height: 44px;
`

const StyledUsernameMobile = styled(Typography)`
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: ${web.color.base.black};
`

const StyledUsernameDesktop = styled(StyledUsernameMobile)`
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
`

const StyledBioMobile = styled(StyledUsernameMobile)``

const StyledBioDesktop = styled(StyledUsernameMobile)`
  font-size: 16px;
  line-height: 24px;
  margin-top: 8px;
`

const StyledSocialContainerMobile = styled(Box)`
  gap: 8px;
  display: flex;
  align-items: center;
  flex-direction: row;
  overflow: auto;
`

const StyledSocialContainerDesktop = styled(StyledSocialContainerMobile)`
  gap: 12px;
  overflow: initial;
`

const StyledButtonsContainer = styled(StyledSocialContainerDesktop)``

const StyledInfoContainerDesktop = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`

const ProfileHeader = () => {
  const isMobile = useMediaQueryState((theme) => theme.breakpoints.down('md'))

  const history = useRouter()
  const { user } = useUser()
  const { profile, isProfileLoading: loading } = useProfileContext()

  const [showMoreSheet, setShowMoreSheet] = React.useState<boolean>(false)

  const getSocialButtons = () => {
    return profile?.socialLinks.map((socialItem, index) => {
      const socialLabel =
        socialItem.socialMediaLabel as keyof typeof SOCIAL_ICON_MAP
      return SOCIAL_ICON_MAP[socialLabel] ? (
        <Button.Tertiary
          key={index}
          target='_blank'
          rel='noopener noreferrer'
          href={socialItem.socialMediaLink}
          size={isMobile ? 'sm' : undefined}
          aria-label={socialItem.socialMediaLabel}
          icon={React.createElement(SOCIAL_ICON_MAP[socialLabel])}
        />
      ) : null
    })
  }

  const renderMobileHeader = () => {
    return loading ? (
      <StyledProfileContainerMobile>
        <StyledProfileContainerPicMobile>
          <StyledProfilePicSkeleton
            animation='wave'
            variant='circular'
            width={84}
            height={84}
          />
          <StyledInfoContainerDesktop>
            <Skeleton
              style={{ margin: 0 }}
              animation='wave'
              variant='text'
              width={100}
              height={40}
            />
            <Skeleton
              style={{ margin: 0 }}
              animation='wave'
              variant='text'
              width={100}
              height={20}
            />
          </StyledInfoContainerDesktop>
        </StyledProfileContainerPicMobile>
        <Skeleton
          style={{ margin: 0 }}
          animation='wave'
          variant='text'
          width='100%'
          height={80}
        />
      </StyledProfileContainerMobile>
    ) : (
      <StyledProfileContainerMobile>
        <StyledProfileContainerPicMobile>
          <StyledProfilePicMobile
            alt={profile?.name}
            src={profile?.profilePicUrl}
          />
          <StyledInfoContainerDesktop>
            <StyledNameMobile align='left' component='h1'>
              {profile?.name}
            </StyledNameMobile>
            <StyledUsernameMobile align='left'>
              @{profile?.profileUrl}
            </StyledUsernameMobile>
          </StyledInfoContainerDesktop>
        </StyledProfileContainerPicMobile>
        <StyledButtonsContainer>
          {profile && profile?.socialLinks?.length > 0 && (
            <StyledSocialContainerMobile>
              {getSocialButtons()}
            </StyledSocialContainerMobile>
          )}
          {!user.notLoggedIn && user?.id === profile?.id && (
            <>
              <Divider orientation='vertical' variant='middle' flexItem />
              <Button.Tertiary
                size='sm'
                aria-label='Edit profile'
                icon={<IconEditFilled />}
                onClick={() => history.push(URL.ACCOUNT)}
              />
              <Button.Tertiary
                size='sm'
                aria-label='More options'
                icon={<IconMoreHorizontal />}
                onClick={() => setShowMoreSheet(true)}
              />
            </>
          )}
        </StyledButtonsContainer>
        {profile?.bio && profile?.bio !== 'None' && (
          <StyledBioMobile align='left'>{profile?.bio}</StyledBioMobile>
        )}
      </StyledProfileContainerMobile>
    )
  }

  const renderDesktopHeader = () => {
    return loading ? (
      <StyledProfileContainerDesktop>
        <StyledProfilePicSkeletonDesktop
          animation='wave'
          variant='circular'
          width={164}
          height={164}
        />
        <StyledInfoContainerDesktop>
          <Skeleton
            style={{ margin: '0' }}
            animation='wave'
            variant='text'
            width={200}
            height={60}
          />
          <Skeleton
            style={{ margin: '0' }}
            animation='wave'
            variant='text'
            width={400}
            height={110}
          />
        </StyledInfoContainerDesktop>
      </StyledProfileContainerDesktop>
    ) : (
      <StyledProfileContainerDesktop>
        <StyledProfilePicDesktop
          alt={profile?.name}
          src={profile?.profilePicUrl}
        />
        <StyledInfoContainerDesktop>
          <StyledNameDesktop align='left' component='h1'>
            {profile?.name}
          </StyledNameDesktop>
          <StyledButtonsContainer>
            <StyledUsernameDesktop align='left'>
              @{profile?.profileUrl}
            </StyledUsernameDesktop>
            {profile && profile?.socialLinks?.length > 0 && (
              <>
                <Divider orientation='vertical' variant='middle' flexItem />
                <StyledSocialContainerDesktop>
                  {getSocialButtons()}
                </StyledSocialContainerDesktop>
              </>
            )}
            {!user.notLoggedIn && user?.id === profile?.id && (
              <>
                <Divider orientation='vertical' variant='middle' flexItem />
                <Button.Tertiary
                  aria-label='Edit profile'
                  icon={
                    <Tooltip placement='top' body='Edit profile'>
                      <Box>
                        <IconEditFilled />
                      </Box>
                    </Tooltip>
                  }
                  onClick={() => history.push(URL.ACCOUNT)}
                />
                <HubbleMenu
                  endIcon={null}
                  label={
                    <Tooltip placement='top' body='More actions'>
                      <Box>
                        <IconMoreHorizontal />
                      </Box>
                    </Tooltip>
                  }
                  buttonProps={{
                    isIconButton: true,
                    variant: 'tertiary',
                  }}
                >
                  <HubbleMenu.Item onClick={() => history.push(URL.DEPOSIT)}>
                    Deposit an NFT
                  </HubbleMenu.Item>
                  <HubbleMenu.Item
                    onClick={() => history.push(URL.WALLET_PREFERENCES)}
                  >
                    Link an External Wallet
                  </HubbleMenu.Item>
                </HubbleMenu>
              </>
            )}
          </StyledButtonsContainer>
          {profile?.bio && profile?.bio !== 'None' && (
            <StyledBioDesktop align='left'>{profile?.bio}</StyledBioDesktop>
          )}
        </StyledInfoContainerDesktop>
      </StyledProfileContainerDesktop>
    )
  }

  return (
    <>
      <HubbleGrid>
        <HubbleGridChild columns={[4, 8, 8]}>
          {isMobile ? renderMobileHeader() : renderDesktopHeader()}
        </HubbleGridChild>
      </HubbleGrid>

      <HubbleSheet
        id='more-sheet'
        open={showMoreSheet}
        onOpen={() => setShowMoreSheet(true)}
        onClose={() => setShowMoreSheet(false)}
      >
        <Box
          sx={{
            mb: 3,
          }}
        >
          <Box
            sx={{
              py: 2,
              borderBottom: `1px solid ${web.color.lightMode.border.primary}`,
            }}
            onClick={() => history.push(URL.DEPOSIT)}
          >
            Deposit an NFT
          </Box>
          <Box
            sx={{
              py: 2,
              borderBottom: `1px solid ${web.color.lightMode.border.primary}`,
            }}
            onClick={() => history.push(URL.WALLET_PREFERENCES)}
          >
            Link an External Wallet
          </Box>
        </Box>
      </HubbleSheet>
    </>
  )
}

export default ProfileHeader
