import(/* webpackMode: "eager" */ "/codebuild/output/src1562939393/src/nifty-nextjs/node_modules/@mui/material/Container/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["Hydrate","useHydrate"] */ "/codebuild/output/src1562939393/src/nifty-nextjs/node_modules/@tanstack/react-query/build/lib/Hydrate.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["IsRestoringProvider","useIsRestoring"] */ "/codebuild/output/src1562939393/src/nifty-nextjs/node_modules/@tanstack/react-query/build/lib/isRestoring.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["QueryClientProvider","defaultContext","useQueryClient"] */ "/codebuild/output/src1562939393/src/nifty-nextjs/node_modules/@tanstack/react-query/build/lib/QueryClientProvider.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["QueryErrorResetBoundary","useQueryErrorResetBoundary"] */ "/codebuild/output/src1562939393/src/nifty-nextjs/node_modules/@tanstack/react-query/build/lib/QueryErrorResetBoundary.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["unstable_batchedUpdates"] */ "/codebuild/output/src1562939393/src/nifty-nextjs/node_modules/@tanstack/react-query/build/lib/reactBatchedUpdates.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["useInfiniteQuery"] */ "/codebuild/output/src1562939393/src/nifty-nextjs/node_modules/@tanstack/react-query/build/lib/useInfiniteQuery.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["useIsFetching"] */ "/codebuild/output/src1562939393/src/nifty-nextjs/node_modules/@tanstack/react-query/build/lib/useIsFetching.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["useIsMutating"] */ "/codebuild/output/src1562939393/src/nifty-nextjs/node_modules/@tanstack/react-query/build/lib/useIsMutating.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["useMutation"] */ "/codebuild/output/src1562939393/src/nifty-nextjs/node_modules/@tanstack/react-query/build/lib/useMutation.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["useQueries"] */ "/codebuild/output/src1562939393/src/nifty-nextjs/node_modules/@tanstack/react-query/build/lib/useQueries.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["useQuery"] */ "/codebuild/output/src1562939393/src/nifty-nextjs/node_modules/@tanstack/react-query/build/lib/useQuery.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/codebuild/output/src1562939393/src/nifty-nextjs/src/app/(ng-core)/profile/[username]/_components/Header.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/codebuild/output/src1562939393/src/nifty-nextjs/src/app/(ng-core)/profile/[username]/_components/Tabs.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/codebuild/output/src1562939393/src/nifty-nextjs/src/app/(ng-core)/profile/[username]/not-found.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["AUTH_STORAGE_KEY"] */ "/codebuild/output/src1562939393/src/nifty-nextjs/src/providers/AuthProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/codebuild/output/src1562939393/src/nifty-nextjs/src/providers/MarketplaceSidebarProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ProfileProvider"] */ "/codebuild/output/src1562939393/src/nifty-nextjs/src/providers/ProfileContext.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["queryClientDefaultOptions"] */ "/codebuild/output/src1562939393/src/nifty-nextjs/src/providers/ReactQueryClientProvider.tsx");
