'use client'

import { useParams, usePathname, useRouter } from 'next/navigation'
import { useEffectOnce } from 'react-use'

import { Segment } from '@hubble/web'
import { web } from '@hubble/tokens'
import { Box, Divider, styled } from '@mui/material'

import { URL } from '@/app/constants'
import { ProfileRouterParams } from '@/types/profile'

import { useAnalytics } from '@/providers/AnalyticsContext'
import useMediaQueryState from '@/hooks/useMediaQueryState'
import { useProfileContext } from '@/providers/ProfileContext'
import useExploreFeatureFlag from '@/hooks/useExploreFeatureFlag'

interface StyleProps {
  isMobile: boolean
  showScroll: boolean
}
const StyledSegment = styled(Segment)<StyleProps>`
  & > div {
    ${({ isMobile, showScroll }) =>
      showScroll
        ? {
            scrollbarWidth: 'thin',
            overflowX: isMobile ? 'scroll' : 'hidden',
            '&::-webkit-scrollbar': {
              width: 6,
              height: 6,
              backgroundColor: 'transparent',
            },
            '&::-webkit-scrollbar-track': {
              backgroundColor: 'transparent',
            },
            '&::-webkit-scrollbar-thumb': {
              minWidth: 24,
              minHeight: 24,
              borderRadius: 6,
              backgroundColor: web.color.lightMode.content.disabled,
            },
          }
        : undefined}
  }

  & > div > a {
    width: ${({ isMobile }) => (isMobile ? '100%' : 'auto')};
    min-width: ${({ isMobile, showScroll }) =>
      isMobile && showScroll ? '80px' : 'unset'};
  }
`

export default function ProfileTabs() {
  const { username } = useParams<Partial<ProfileRouterParams>>()
  const { logEvent, logPageView, logTimeEvent } = useAnalytics()
  const {
    isOwnProfile,
    isCreator,
    profile,
    isProfileLoading: loading,
  } = useProfileContext()
  const isMobile = useMediaQueryState((theme) => theme.breakpoints.down('md'))
  const pathname = usePathname()
  const router = useRouter()
  const isExplorePageEnabled = useExploreFeatureFlag()

  const generateProfilePath = (path: string, name: string) => {
    if (!name) {
      return null
    }
    return { path, url: path.replace(':username', name) }
  }

  const collectionCount = loading ? '--' : profile?.stores?.length
  const baseUrl = URL.PROFILEV2
  const currentTab =
    pathname || generateProfilePath(baseUrl, username ?? '')?.url
  const isDashboard = isOwnProfile && isCreator
  const hasCollections =
    collectionCount &&
    typeof collectionCount === 'number' &&
    collectionCount > 0

  const profilePaths = {
    collections: generateProfilePath(`${baseUrl}/collections/`, username || ''),
    nfts: generateProfilePath(`${baseUrl}/`, username || ''),
    favorites: generateProfilePath(`${baseUrl}/favorites/`, username || ''),
    activity: generateProfilePath(`${baseUrl}/activity/`, username || ''),
    artistDashboard: generateProfilePath(`${baseUrl}/creator-dashboard/`, username || ''),
  }

  const getButtonGroupOptions = () => {
    const buttonGroupOptions = [
      {
        label: 'Collections',
        testId: 'collectionsTab',
        value: profilePaths.collections?.url,
        onClick: () =>
          logEvent?.('Profile Page Collections tab clicked', 'Navigation'),
      },
      {
        label: 'Collected',
        testId: 'collectedTab',
        value: profilePaths.nfts?.url,
        onClick: () =>
          logEvent?.(
            isOwnProfile
              ? 'Profile Page My NFTs tab clicked'
              : 'Profile Page NFTs tab clicked ',
            'Navigation',
          ),
      },
      {
        label: 'Liked',
        testId: 'likedTab',
        value: profilePaths.favorites?.url,
        onClick: () =>
          logEvent?.('Profile Page Likes tab clicked', 'Navigation'),
      },
      {
        label: isMobile ? 'Dashboard' : 'Creator Dashboard',
        testId: 'creatorDashboardTab',
        value: profilePaths.artistDashboard?.url,
        onClick: () =>
          logEvent?.(
            'Profile Page Creator Dashboard tab clicked',
            'Navigation',
          ),
      },
      {
        label: isMobile ? 'Activity' : 'Purchase Activity',
        value: profilePaths.activity?.url,
        testId: 'purchasedTab',
        onClick: () =>
          logEvent?.(
            'Profile Page Purchase Activity tab clicked',
            'Navigation',
          ),
      },
    ]

    const filterOptions: string[] = []
    if (isExplorePageEnabled || !hasCollections)
      filterOptions.push(profilePaths.collections?.url!)
    if (!isDashboard) filterOptions.push(profilePaths.artistDashboard?.url!)
    if (!isOwnProfile) filterOptions.push(profilePaths.activity?.url!)

    return buttonGroupOptions.filter(
      (option) => !filterOptions.includes(option.value!),
    )
  }

  useEffectOnce(() => {
    logPageView?.('Profile Page Viewed', {
      username,
    })
    logTimeEvent?.('Profile Page Left')

    return () => {
      logPageView?.('Profile Page Left', {
        username,
      })
    }
  })

  return (
    <>
      <Box
        sx={{
          width: '100%',
          mt: 4,
        }}
      >
        <StyledSegment
          isMobile={isMobile ?? false}
          showScroll={hasCollections || isDashboard}
          mt={0}
          value={currentTab}
          stretch={isMobile ?? false}
          hasBackground={isMobile ?? false}
          size={isMobile ? 'sm' : 'md'}
          onChange={(path) => router.push(path)}
        >
          {getButtonGroupOptions().map((button) => (
            <Segment.Button
              key={button.value}
              cta={button.label}
              href={button.value}
              value={button.value!}
              onClick={(event) => {
                event?.preventDefault()
                event?.stopPropagation()

                button.onClick()
              }}
              data-testid={button.testId}
            />
          ))}
        </StyledSegment>
      </Box>
      <Divider sx={{ mt: 1.5 }} light />
    </>
  )
}
